import React from "react";
import { QRCodeSVG } from "qrcode.react";

import image from "../Images/SNKioskArrow.png";
import {
  ThxContainer,
  ThxHeaderWrapper,
  ThxBodyWrapper,
  DigitalWrapper,
  DigitalContent,
  QrCodeStyles,
  ThxButtonWrapper,
} from "../Styles/ViewStyles/ThankYouStyles";
import Button from "../Components/Button";
import { connect } from "react-redux";

const venueMap = {
  cgg: "cgg",
};

const ThankYou = (props) => {
  const { client, orderConfirmation, viewTicketsLink } = props;

  const venue = venueMap[client] || "sn";
  const link = viewTicketsLink
    .replace("{venue}", venue)
    .replace("{orderConfirmation}", orderConfirmation);

  const handleClick = () => {
    props.history.push("/");
  };

  return props.location?.state?.isDigital ? (
    <DigitalWrapper>
      <DigitalContent client={client}>
        <div>Use your camera app or QR code reader on your mobile device</div>
        <div>
          <QRCodeSVG style={QrCodeStyles} value={link} className='qr-code-link' />
        </div>
        <div>Scan QR code for your TICKET and press EXIT</div>
        <Button
          click={handleClick}
          label="EXIT"
          client={client}
          width={"250px"}
          height={"80px"}
        />
      </DigitalContent>
    </DigitalWrapper>
  ) : (
    <>
      <ThxButtonWrapper>
        <Button click={handleClick} label="EXIT" client={client} />
      </ThxButtonWrapper>
      <ThxContainer client={client}>
        <ThxHeaderWrapper client={client}>
          <h4>
            {client !== "cgg"
              ? "YOUR TICKETS ARE BEING PRINTED"
              : "THANK YOU! YOUR TICKETS ARE BEING PRINTED"}
          </h4>
        </ThxHeaderWrapper>
        {client !== "cgg" && (
          <ThxBodyWrapper>
            <img src={image} alt="" />
            <h4>
              Enter outside near the <br /> Ticket Booth at the time <br />
              printed on your ticket
            </h4>
          </ThxBodyWrapper>
        )}
      </ThxContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    client: state.client,
    orderConfirmation: state.orderStatus.orderConfirmation,
    viewTicketsLink: state.viewTicketsLink,
  };
};

export default connect(mapStateToProps)(ThankYou);
