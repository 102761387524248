import React from "react";

import { connect } from "react-redux";

import {
  Button,
  LongButton,
  TriangleSN,
  TriangleCGG,
} from "../Styles/ComponentStyles/ButtonStyles";

const img = require("../Images/PurchaseTicketIcon.png");

const triangle = {
  sn: TriangleSN,
  pike: TriangleSN,
  cgg: TriangleCGG,
};

const Butt = ({
  long,
  click,
  label,
  width,
  second,
  bold,
  height,
  client,
  iconName,
  iconFontSize,
  disabled,
}) => {
  if (long) {
    return (
      <LongButton
        onClick={disabled ? undefined : click}
        second={second}
        client={client}
        disabled={disabled}
      >
        <h2 data-disabled={disabled ? "true" : "false"}>
          {client === "cgg" && (
            <div
              style={{
                backgroundImage: `url(${img})`,
                width: "28px",
                height: "28px",
                display: "inline-block",
                position: "relative",
                verticalAlign: "middle",
                marginRight: "14px",
              }}
            />
          )}
          {label}
        </h2>
      </LongButton>
    );
  }

  const backIcon = label === "BACK";
  const BackIcon = triangle[client];

  return (
    <Button
      onClick={click}
      width={width}
      second={second}
      bold={bold}
      height={height}
      client={client}
      style={{ justifyContent: backIcon ? "flex-start" : "center" }}
      data-disabled={disabled ? "true" : "false"}
    >
      <h6>
        {iconName && (
          <i
            className="material-icons"
            style={{
              verticalAlign: "top",
              fontSize: `${iconFontSize}px`,
              fontWeight: "bolder",
              marginRight: iconName === "check" ? "2px" : "",
            }}
          >
            {iconName}
          </i>
        )}
        {backIcon && <BackIcon />}
        <span style={{ marginTop: iconName ? "1px" : "" }}>{label}</span>
      </h6>
    </Button>
  );
};

const mapStateToProps = (state) => {
  return {
    client: state.client,
  };
};
export default connect(mapStateToProps)(Butt);
