export default {
  style: {
    primaryOrange: "hsl(17, 72%, 50%)",
    primary: "hsl(220, 2%, 36%) ",
    primaryDisabled: "#A1C7E2",
    primaryLight: "#3E3F41",
    secondary: "hsl(220, 2%, 36%)",
    disabled: "#DAD9D7",
    fontColor: "#ffff",
    font: "BrandonGrotesque-Regular",
    boldFont: "BrandonGrotesque-Bold",
    altFont: "cocon",
    altFont2: "knockout",
    backgroundColor: "black",
  },
  resources: {
    name: "CHIHULY GARDEN AND GLASS",
    memberVenueId: 44,
    city: "Seattle",
    voucherId: 226,
    salesTerminalTypeId: 38,
  },
};
