import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretUp as caret,
  faChevronUp as chevron,
} from "@fortawesome/free-solid-svg-icons";

import {
  iconColor as calculateIconColor,
  ButtonBox,
} from "../../Styles/ComponentStyles/TimeSlotSelection/ScrollButtonStyles";

const ScrollButtons = (props) => {
  const { clickUp, clickDown, client } = props;
  const iconColor = calculateIconColor({ client });

  return (
    <ButtonBox client={client}>
      <div onClick={clickDown} className="lightButton">
        {client !== "cgg" ? (
          <FontAwesomeIcon
            icon={caret}
            size="2x"
            rotation={180}
            color={iconColor}
            style={{ margin: "7px" }}
          />
        ) : (
          <FontAwesomeIcon
            icon={chevron}
            rotation={180}
            color={iconColor}
            style={{ fontSize: "1.5em", margin: "15px" }}
          />
        )}
      </div>
      <div onClick={clickUp}>
        {client !== "cgg" ? (
          <FontAwesomeIcon
            icon={caret}
            size="2x"
            color={iconColor}
            style={{ margin: "7px" }}
          />
        ) : (
          <FontAwesomeIcon
            icon={chevron}
            color={iconColor}
            style={{ fontSize: "1.5em", margin: "15px" }}
          />
        )}
      </div>
    </ButtonBox>
  );
};

export default ScrollButtons;
