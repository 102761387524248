import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Popover, PopoverBody } from "reactstrap";
import Button from "./Button";
import DateTimeDisplay from "./DateTimeDisplay";
import DatePicker from "./DateSelector/DatePicker";

import {
  ContentWrapper,
  RightBox,
  popoverStyle,
  CalendarWrapper,
  CalendarIconWrapper,
  BarWrapper,
  SubTitle,
} from "../Styles/ComponentStyles/TopBarStyles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

class TopBar extends Component {
  constructor() {
    super();

    this.state = { isOpen: false };

    this.handleBack = () => {
      if (this.props.backHandler) {
        this.props.backHandler();
        return;
      }
      this.props.history.goBack();
    };

    this.handleStartOver = () => {
      this.props.history.push("/");
    };
  }

  componentDidUpdate() {
    if (this.state.isOpen) {
      window.addEventListener("click", this.show);
    } else {
      window.removeEventListener("click", this.show);
    }
  }

  show = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { client, backOnly, startOverOnly, isExchange } = this.props;
    const iconFontSize = client === "cgg" ? 30 : 40;

    const { pathname } = window.location;
    const isCalendarPresent =
      pathname === "/ProductSelection" ||
      (pathname === "/EntryTime" && isExchange) ||
      pathname === "/CityPassExchange";

    const backButtonAbsentList = ["/ZipCode", "/Checkout"];

    const isBackAvailable = !backButtonAbsentList.includes(pathname);

    return (
      <BarWrapper>
        <ContentWrapper>
          <div className="buttons-left">
            {isBackAvailable && (
              <Button
                label={"BACK"}
                click={this.handleBack}
                bold
                iconFontSize={iconFontSize}
              />
            )}
            {(!backOnly || startOverOnly) && (
              <Button label={"START OVER"} click={this.handleStartOver} bold />
            )}
          </div>

          <RightBox client={client}>
            {isCalendarPresent && (
              <>
                <CalendarWrapper>
                  <div>
                    CHANGE <br /> VISIT DATE
                  </div>
                  <CalendarIconWrapper>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      onClick={this.show}
                      id="Popover1"
                      size="3x"
                    />
                  </CalendarIconWrapper>
                </CalendarWrapper>

                <Popover
                  id="Popper"
                  placement="bottom"
                  isOpen={this.state.isOpen}
                  target="Popover1"
                  toggle={this.show}
                  onBlur={this.show}
                >
                  <PopoverBody style={popoverStyle}>
                    {<DatePicker show={this.show} />}
                  </PopoverBody>
                </Popover>
              </>
            )}
            <DateTimeDisplay current={true} />
          </RightBox>
        </ContentWrapper>

        {this.props.title && (
          <SubTitle client={client}>{this.props.title}</SubTitle>
        )}
      </BarWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentProduct: state.currentProduct,
    cart: state.cart,
    client: state.client,
    isExchange: state.isExchange,
  };
};

export default connect(mapStateToProps)(withRouter(TopBar));
