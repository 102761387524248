import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const PromoBodyWrapper = styled.div`
  display: flex;
  margin: 0 7%;
  justify-content: space-between;
`;

export const HorizontalPromoCardWrapper = styled.div`
  width: 660px;
`;

export const TotalTicketsBox = styled.div`
  height: 10vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  h4 {
    font-family: ${(props) => getStyle(props.client).boldFont};
  }
`;

export const ProductCardWrapper = styled.div`
  color: black;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 60px;
  padding-top: 0;
`;
