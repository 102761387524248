export const DATECHANGE = "DATECHANGE";
export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_SELECT = "PRODUCT_SELECT";
export const PRODUCT_SELECT_N = "PRODUCT_SELECT_N";
export const PROD_CATEGORY_CHANGE = "PROD_CATEGORY_CHANGE";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_CART_N = "ADD_TO_CART_N";
export const PROMO_SUCCESS = "PROMO_SUCCESS";
export const DISCOUNT_SUCCESS = "DISCOUNT_SUCCESS";
export const PROD_TIMES_SUCCESS = "PROD_TIMES_SUCCESS";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const RESET_STATE = "RESET_STATE";
export const RESET_CART = "RESET_CART";
export const RESET_ORDER = "RESET_ORDER";
export const TIME_SELECT = "TIME_SELECT";
export const TIME_SELECT_N = "TIME_SELECT_N";
export const GET_UPGRADES = "GET_UPGRADES";
export const TICKET_PRINT_SOCKET = "TICKET_PRINT_SOCKET";
export const BOCA_STATUS_SOCKET = "BOCA_STATUS_SOCKET";
export const ENABLE_KIOSK = "ENABLE_KIOSK";
export const DISABLE_KIOSK = "DISABLE_KIOSK";
export const IS_PRINTER_ONLINE = "IS_PRINTER_ONLINE";
export const IS_AGREED_ONLY_DIGITAL = "IS_AGREED_ONLY_DIGITAL";
export const SAVE_UPGRADE_INFO = "SAVE_UPGRADE_INFO";
export const EXCHANGE_CART = "EXCHANGE_CART";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const UPDATE_EXCHANGE_CART_FOR_CHECKOUT =
  "UPDATE_EXCHANGE_CART_FOR_CHECKOUT";
export const ADD_EXCHANGE_TIMESLOT = "ADD_EXCHANGE_TIMESLOT";
export const SKIP_UPGRADE = "SKIP_UPGRADE";
export const SET_API_DATA = "SET_API_DATA";
export const ORDER_ERROR = "ORDER_ERROR";
export const RESET_TIME_SELECT = "RESET_TIME_SELECT";
export const SET_ERROR = "SET_ERROR";
export const RESET_UPGRADES = "RESET_UPGRADES";
export const SET_TOTAL_TICKETS_PRICE = "SET_TOTAL_TICKETS_PRICE";
export const SET_CURRENT_DATE = "SET_CURRENT_DATE";
export const SET_EXCHANGING_MODE = "SET_EXCHANGING_MODE";
