import styled from "styled-components";

export const DateBox = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  margin: 0;
  background-color: ${(props) => (props.selected ? "#3F3E3C" : "white")};
  color: ${(props) => (props.selected ? "white" : "black")};
  &:hover {
    background-color: gray;
  }

  border-bottom: ${(props) => (props.brake ? "1px solid black" : null)};
  .icon {
    visibility: ${(props) => (props.selected ? "visible" : "hidden")};
  }
`;
