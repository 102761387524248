import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Buttons from "../Components/Landing/buttonContainer";

import {
  Landing,
  TitleText,
  Welcome,
} from "../Styles/ViewStyles/LandingStyles";
import PrinterDown from "./PrinterDown";
import {
  AddTicketSocket,
  AddBocaSocket,
  fetchProductsList,
  resetState,
  createNewWebSocket,
} from "../Actions/actionCreator";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      exchange: false,
    };

    this.ClickHandle = () => {
      this.setState({
        redirect: true,
      });
    };

    this.ClickForExchange = () => {
      this.setState({ exchange: true });
    };
  }

  componentDidMount() {
    const { fetchProductsList, resetState, createNewWebSocket } = this.props;

    resetState();

    createNewWebSocket();

    fetchProductsList();
  }

  componentDidUpdate(prevProps) {
    const { fetchProductsList, printerOnline } = this.props;
    if (prevProps.printerOnline !== printerOnline) {
      fetchProductsList();
    }
  }

  render() {
    const { client, isKioskAllowedToUse } = this.props;
    const { redirect, exchange } = this.state;

    if (redirect) {
      return <Redirect push to={"/ProductSelection"} />;
    }
    if (exchange) {
      return <Redirect push to={"/CityPassExchange"} />;
    }

    return (
      <>
        {client !== "cgg" ? (
          <Landing client={client}>
            <TitleText client={client}>
              <h1>
                Recently transformed, totally transforming <br />
                <span className="largeText">THE FUTURE. NOW OPEN.</span>
              </h1>
            </TitleText>

            {isKioskAllowedToUse ? (
              <Buttons
                click1={this.ClickHandle}
                click2={this.ClickForExchange}
                client={this.props.client}
              />
            ) : (
              <PrinterDown />
            )}
          </Landing>
        ) : (
          <Landing client={client}>
            <Welcome client={client}>WELCOME</Welcome>

            {isKioskAllowedToUse ? (
              <Buttons
                click1={this.ClickHandle}
                click2={this.ClickForExchange}
                client={this.props.client}
              />
            ) : (
              <PrinterDown />
            )}
          </Landing>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bocaStatusWebSocket: state.bocaStatusWebSocket,
    ticketPrintWebSocket: state.ticketPrintWebSocket,
    printerOnline: state.printerOnline,
    client: state.client,
    bocaStatusUrl: state.bocaStatusUrl,
    printerSocketUrl: state.printerSocketUrl,
    isKioskAllowedToUse: state.isKioskAllowedToUse,
  };
};

const mapDispatchToProps = {
  AddTicketSocket,
  AddBocaSocket,
  fetchProductsList,
  resetState,
  createNewWebSocket,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
