import React from 'react';
import { TwoWeekDateRange } from '../../Utils/Helpers/dateHelpers';
import DateForSelector from './DateForSelector';
import styled from 'styled-components';

const DateWrapper = styled.div`
  width: 300px;
`;

const DatePicker = (props) => {
  let dates = TwoWeekDateRange();
  return (
    <DateWrapper>
      <div>
        {dates.map((date, i) => (
          <DateForSelector key={i} date={date} show={props.show} />
        ))}
      </div>
    </DateWrapper>
  );
};
export default DatePicker;
