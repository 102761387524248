import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

import TopBar from "../Components/TopBar";
import Button from "../Components/Button";
import Tab from "../Components/ProductSelection/AttractionTab";
import Carousel from "../Components/Carousel/Carousel";
import SelectionHeader from "../Components/SelectionHeader";
import Loading from "../Components/Loading";

import {
  selectProduct,
  changeProdList,
  resetCart,
  resetUpgrades,
  selectTime,
  selectUpgrade,
  addTotalTicketsPrice,
  setCurrentDate,
  checkCurrentProductListIsActual,
} from "../Actions/actionCreator";

import {
  ProductsWrapper,
  CarouselWrapper,
} from "../Styles/ViewStyles/ProductSelectionStyles";

import SwipeIcon from "../Images/cgg/SwipeIcon.png";
import { GenericModal } from "../Components/Modal";

class ProductSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      currentIndex: 0,
      isModalOpened: false,
    };

    this.handleClick = () => {
      let id;
      const products = this.props.productList;
      products.length === 3 && this.state.currentIndex > 2
        ? (id = this.state.currentIndex - 3)
        : products.length === 2 && this.state.currentIndex > 1
          ? (id = this.state.currentIndex - 2)
          : (id = this.state.currentIndex);
      this.props.selectProduct(products[id]);
      this.setState({
        redirect: true,
        currentIndex: 0,
      });
    };

    this.closeModal = () => {
      this.setState({ isModalOpened: false });
    };
  }

  componentDidMount() {
    this.props.checkCurrentProductListIsActual();
    this.props.resetCart();
    this.props.resetUpgrades();
    this.props.selectTime(null, {});
    this.props.selectUpgrade(false);
    this.props.addTotalTicketsPrice(null);
    this.props.setCurrentDate();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productList !== this.props.productList) {
      this.setState({ currentIndex: 0 });
    }
  }

  handleChangeIndex = (id) => {
    this.setState({ currentIndex: id });
  };

  changeTab = (tab) => {
    const { changeProdList, fullProductList } = this.props;
    const isOtherAttrPresent = fullProductList.some(
      (el) => el.exchangeProductYN
    );
    if (!isOtherAttrPresent) {
      this.setState({ isModalOpened: true });
      return;
    }
    changeProdList(fullProductList, tab);
  };

  render() {
    const { isKioskAllowedToUse, client, isLoading } = this.props;

    if (!isKioskAllowedToUse) {
      return <Redirect exact push to={"/"} />;
    }
    if (this.state.redirect) {
      return <Redirect push to={"/QtySelection"} />;
    }

    return (
      <>
        <GenericModal
          label="No product available."
          open={this.state.isModalOpened}
          client={client}
          click={this.closeModal}
        />
        <TopBar backOnly />
        <SelectionHeader header={"SELECT YOUR TICKET"} />
        <Tab changeTab={this.changeTab} />

        {isLoading ? (
          <Loading />
        ) : (
          <>
            <CarouselWrapper>
              <Carousel
                currentIndex={this.state.currentIndex}
                handleChangeIndex={this.handleChangeIndex}
              />
            </CarouselWrapper>
            <ProductsWrapper>
              <Button
                click={this.handleClick}
                label={"SELECT"}
                second={client === "cgg" ? false : true}
                width="28%"
                height={"12.5vh"}
                bold
                iconName={client === "cgg" && "check"}
                iconFontSize="22"
              />
              <div className="bottomWrapper" style={{ width: "18%" }}>
                {client !== "cgg" ? (
                  <>
                    <FontAwesomeIcon
                      icon={faCaretLeft}
                      size="3x"
                      color={"white"}
                    />
                    <p style={{ margin: "auto" }}>
                      SWIPE TO SEE OTHER
                      <br />
                      AVAILABLE TICKETS
                    </p>
                    <FontAwesomeIcon
                      icon={faCaretRight}
                      size="3x"
                      color={"white"}
                    />
                  </>
                ) : (
                  <img
                    src={SwipeIcon}
                    style={{
                      margin: "auto",
                      width: "116px",
                      height: "86px",
                    }}
                    alt=""
                  />
                )}
              </div>
            </ProductsWrapper>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedDate: state.selectedDate,
    productList: state.productList,
    fullProductList: state.fullProductList,
    isKioskAllowedToUse: state.isKioskAllowedToUse,
    client: state.client,
    cart: state.cart,
    text: state.text,
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = {
  selectProduct,
  changeProdList,
  resetCart,
  resetUpgrades,
  selectTime,
  selectUpgrade,
  addTotalTicketsPrice,
  setCurrentDate,
  checkCurrentProductListIsActual,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelection);
