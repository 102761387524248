import styled, { keyframes } from 'styled-components';

import { getStyle } from '../../../Utils/Helpers/getStyleOrResources';

const clicked = keyframes`
    from { transform: scale(.95) }
    to {transform: scale(1)}
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4%;

  div {
    width: 48%;
    display: flex;
    justify-content: center;
    background-color: ${props => getStyle(props.client).primary};

    &:hover {
      animation: ${clicked} 0.3s ease-in-out;
    }
  }
  .lightButton {
    background-color: ${props => getStyle(props.client).primaryLight};
  }
`;

export const iconColor = props => getStyle(props.client).fontColor;
