import styled from "styled-components";
import { createGlobalStyle } from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => (props.client !== "cgg" ? "#333838" : "beige")} !important;
    height: 100%;
  }
`;

export const AppWrapper = styled.div`
  background: ${(props) =>
    `url(${props.bgImg}) #000 no-repeat ${props.client !== "cgg" ? "center" : "right"} center`};
  background-size: ${(props) => (props.client !== "cgg" ? "100% 101%" : "")};
  width: 1280px;
  height: 1024px;
  overflow: hidden;
  margin: 0 auto;
  font-family: ${(props) => getStyle(props.client).font};
`;

export const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
`;
