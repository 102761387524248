import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: ${(props) => getStyle(props.client).fontColor};
  line-height: 1;
  min-height: ${(props) => (props.main ? "auto" : "100px")};

  p {
    font-family: ${(props) => getStyle(props.client).font};
  }

  h4 {
    padding: 0;
    margin: 0;
    font-size: 1.3em;
    font-weight: bold;
  }
  .visitDate {
    /*margin-top: 1%;*/
    margin-top: 5px;
    display: flex;
  }

  .visitDate span {
    margin-left: 5px;
    font-size: ${(props) => (props.client === "cgg" ? "1em" : "1.4em")};
    font-family: ${(props) =>
      props.client === "cgg" ? (props) => getStyle(props.client).boldFont : (props) => getStyle(props.client).font};
  }
`;

export const SelectionTitle = styled.h4`
  text-transform: uppercase;
`;
