import React, { useRef } from 'react';

import { connect } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.css';
import disableScroll from 'disable-scroll';

import { PrevArrow } from './PrevArrow';
import NextArrow from './NextArrow';
import ProdCard from '../ProductCard';

const Carousel = props => {
  const { productList, client, handleChangeIndex, currentIndex } = props;
  const sliderRef = useRef(null);

  const smallListLength = productList.length === 3 || productList.length === 2;
  const products = smallListLength ? productList.concat(productList) : productList;

  const handleCarouselScroll = e => {
    disableScroll.on();

    const { deltaY } = e;
    if (sliderRef?.current) {
      deltaY > 0 ? sliderRef.current.slickNext() : sliderRef.current.slickPrev();
    }
  };

  const enableBodyScroll = () => {
    disableScroll.off();
  };

  const settings = {
    dots: false,
    infinite: products.length !== 1,
    centerMode: true,
    slidesToShow: 3,
    focusOnSelect: true,
    swipeToSlide: true,
    drag: true,
    centerPadding: '0px',
    speed: '700',
    easing: 'ease',
    prevArrow: <PrevArrow client={client} />,
    nextArrow: <NextArrow client={client} />,
    className: 'products-carousel',
    initialSlide: 0,
    beforeChange: (current, next) => {
      handleChangeIndex(next < 0 ? 0 : next);
    },
  };

  const sliderStyles = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  };

  return (
    <div onWheel={handleCarouselScroll} onMouseLeave={enableBodyScroll}>
      <Slider {...settings} style={sliderStyles} ref={sliderRef}>
        {products.map((item, i) => (
          <ProdCard
            key={item.id}
            id={item.id}
            alternateText={{ __html: item.longDesc }}
            img={item.imageUrl}
            prodName={item.name}
            subTitle={item.shortDesc}
            focus={currentIndex === i}
            currentIndex={currentIndex}
            page={1}
          />
        ))}
      </Slider>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    client: state.client,
    productList: state.productList
  };
};

export default connect(mapStateToProps)(Carousel);
