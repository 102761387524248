import React from "react";
import styled, { keyframes } from "styled-components";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import InputMask from "react-input-mask";

import Button from "../Button";

const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10%;

  input {
    height: 40px;
    font-size: 1.2em;
    text-indent: 10px;
    width: 100%;
    box-shadow: none;
    border-top-style: groove;
    border-right-style: groove;
    border-left-style: groove;
    border-bottom-style: groove;
    user-select: none;
  }

  p {
    color: white;
  }
`;

const inWard = keyframes`
 from { transform: translateX(-100%)}
 to {transform: translateX(0%)}
`;

export const KeyboardWrapper = styled.div`
  width: 95%;
  margin: 5% auto;
  border-radius: 5px;
  animation: ${inWard} 1s ease-out;

  .simple-keyboard.hg-theme-default .hg-button {
    height: 70px;
    width: 100px;
  }
`;

export const ExchangeNumPad = ({ value, click, onKeyPress, client }) => {
  return (
    <>
      <ModalBodyWrapper>
        <div>
          <p>
            Please enter your CityPASS ticket number located <br /> below the
            barcode at the bottom of your ticket.{" "}
          </p>
        </div>
        <div>
          <InputMask
            name="voucherCode"
            placeholder="Use keypad to enter barcode"
            value={value}
            onChange={null}
            autoFocus={!!value}
            autoComplete="off"
            mask="9999 9999 9999 9999 999"
            maskChar="-"
          />
        </div>
        <div>
          <KeyboardWrapper>
            <Keyboard
              layout={name}
              maxLength={19}
              layoutName={"nums"}
              display={{
                "{bksp}": '<i class="fas fa-backspace" style="color: red"></i>',
                "{enter}":
                  '<i class="fas fa-sign-in-alt" style="color: green"></i>',
              }}
              mergeDisplay={true}
              onKeyPress={onKeyPress}
            />
          </KeyboardWrapper>
        </div>
        <div>
          <Button
            second={client !== "cgg"}
            label="SUBMIT"
            click={() => click(value)}
            client={client}
          />
        </div>
      </ModalBodyWrapper>
    </>
  );
};

let name = {
  nums: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"],
};
