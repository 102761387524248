import React from "react";
import Button from "../Button";

const Buttons = ({ click1, click2, client }) => {
  const citypassButtonLabel =
    client !== "cgg" ? "CITYPASS EXCHANGE" : "CityPASS TICKET EXCHANGE";

  return (
    <>
      <Button
        click={click1}
        label={"PURCHASE TICKETS"}
        long
        second={client !== "cgg"}
        client={client}
      />
      <Button
        click={click2}
        long
        label={citypassButtonLabel}
        second={client === "cgg"}
        client={client}
      />
    </>
  );
};

export default Buttons;
