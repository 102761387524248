import React from "react";
import styled from "styled-components";

import { Modal } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faQuestion } from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";

const ButtonWrapperModal = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  & > :last-child {
    margin-left: 15px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  h4 {
    margin: 5%;
  }

  .covid-link {
    color: #005780;
  }
`;

export const GenericModal = ({
  label,
  open,
  click,
  click2,
  question,
  client,
  scannedTicketsCount,
  confirmButtonLabel,
}) => {
  return (
    <Modal isOpen={open} centered={true} style={{ maxWidth: 550 }}>
      <div style={{ margin: "5%" }}>
        <TextWrapper client={client}>
          <div style={{ margin: "5%" }}>
            {!question ? (
              <FontAwesomeIcon icon={faExclamation} color={"red"} size="4x" />
            ) : (
              <FontAwesomeIcon
                icon={faQuestion}
                type="regular"
                color={"red"}
                size="4x"
              />
            )}
          </div>
          <h4>
            {!!scannedTicketsCount && (
              <p>Scanned tickets: {scannedTicketsCount} </p>
            )}
            <span dangerouslySetInnerHTML={{ __html: label }}></span>
          </h4>
        </TextWrapper>
        <ButtonWrapperModal>
          <Button
            label={question ? "YES" : confirmButtonLabel || "OK"}
            click={click}
            client={client}
          />
          {question ? (
            <Button label="NO, REDEEM" click={click2} client={client} />
          ) : null}
        </ButtonWrapperModal>
      </div>
    </Modal>
  );
};
