import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import swipeImage from "../Images/SwipeCard.png";
import { GenericModal } from "../Components/Modal";
import Loading from "../Components/Loading";
import TopBar from "../Components/TopBar";
import { getHeaders } from "../Actions/actionCreator";
import {
  CheckoutLoadingWrapper,
  CheckoutWrapper,
} from "../Styles/ViewStyles/CheckoutStyles";

let swipe = "";

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: { isOpened: false },
      loading: false,
    };

    this.processPayment = async (swipe) => {
      if (this.state.loading) {
        return;
      }

      const { pendingOrderId, client, API, history, idleTimer, handleClose } =
        this.props;

      handleClose();
      idleTimer.reset();
      idleTimer.pause();

      this.setState({ loading: true });
      const headers = getHeaders(client);

      const body = {
        orderId: pendingOrderId,
        base64CardStripe: swipe,
        userAccountId: 1137,
      };

      axios
        .post(`${API}/api/kiosk/${pendingOrderId}/payment`, body, headers)
        .then((response) => {
          const {
            data: { successfulYN },
          } = response;
          if (successfulYN) {
            history.push("/ZipCode");
            return;
          }
          this.setState({
            modal: {
              isOpened: true,
              label: "Transaction declined. Please try again.",
            },
            loading: false,
          });
        })
        .catch((err) => {
          this.props.sentDataToLogs(
            "payment",
            err.message || err.FullMessage || ""
          );
          this.setState({
            modal: {
              isOpened: true,
              label:
                err?.response?.data?.Code === 18
                  ? "Card reader error. Please swipe again!"
                  : "Authorization failed. Please try again.",
            },
            loading: false,
          });
        })
        .finally(() => {
          idleTimer.resume();
        });
    };

    this.closeModal = () => {
      this.setState({ modal: { isOpened: false } });
    };

    this.onkeypress = (e) => {
      let key = "";
      let code = e.keyCode ? e.keyCode : e.which;
      key = key + String.fromCharCode(code);
      swipe += key;

      if (code === 13) {
        let baseEncoded = btoa(swipe);
        swipe = "";
        this.processPayment(baseEncoded);
      }
    };
  }

  componentDidMount() {
    window.addEventListener("keypress", this.onkeypress, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keypress", this.onkeypress, false);
  }

  render() {
    const { client, isKioskAllowedToUse } = this.props;
    const { loading, modal } = this.state;
    if (!isKioskAllowedToUse) {
      return <Redirect exact push to={"/"} />;
    }

    return (
      <>
        {loading ? (
          <CheckoutLoadingWrapper>
            <Loading />
          </CheckoutLoadingWrapper>
        ) : (
          <>
            <GenericModal
              label={modal?.label}
              open={modal.isOpened}
              click={this.closeModal}
              client={client}
            />
            <TopBar startOverOnly />
            <CheckoutWrapper client={client}>
              <h3>
                CHECK OUT <br /> QUICKLY INSERT AND REMOVE YOUR CREDIT CARD
                BELOW
              </h3>
              <img src={swipeImage} alt="Card Swipe" />
            </CheckoutWrapper>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pendingOrderId: state.orderStatus.orderId,
    isKioskAllowedToUse: state.isKioskAllowedToUse,
    amount: state.orderStatus.totalPrice,
    client: state.client,
    API: state.API,
  };
};

export default withRouter(connect(mapStateToProps)(Checkout));
