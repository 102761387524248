import React from 'react';
import { connect } from 'react-redux';

import { animateScroll as scroll, Events, scrollSpy, Element } from 'react-scroll';
import { selectTime, addTotalTicketsPrice } from '../../Actions/actionCreator';
import { convertCart, getMultiTimedProductTotalPrice } from '../../Utils/Helpers/helpers';
import { calcTixPrices } from '../../Utils/Helpers/ticketPriceHelper';

import SingleTimeSlot from './SingleTimeSlot';
import DayPartBox from './DayPartBox';
import ScrollButtons from './ScrollButtons';

import {
  TimeSlotBlock,
  TimeSlotWrapper
} from '../../Styles/ComponentStyles/TimeSlotSelection/TimeSlotsStyles';

class TimeSlots extends React.Component {
  constructor(props) {
    super(props);
    this.state = { times: [], currentTime: null };
  }

  componentDidMount = () => {
    const {
      cart,
      currentProduct,
      slotId,
      slotStructure,
      selectTime,
      currentTime
    } = this.props;
    Events.scrollEvent.register('begin', function() {});
    Events.scrollEvent.register('end', function() {});
    scrollSpy.update();
    const priceBreakDown = calcTixPrices(cart, currentProduct, slotId.id, slotStructure);
    const times = this.filterTimes();
    const numTix = convertCart(cart, currentProduct.id).qty;
    if (numTix > currentTime?.quantity) {
      selectTime(null, {});
    }

    this.setState({
      times,
      price: priceBreakDown.price,
      tixSummary: priceBreakDown.tixSummary
    });
  };

  componentWillUnmount = () => {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  };

  filterTimes = () => {
    const {
      availTimes,
      slotId,
      cart,
      currentProduct,
      currentTime,
      slotStructure,
      totalTicketsPrice,
      isExchange,
      exchangeCart,
      productId,
    } = this.props;
    const now = new Date();

    const selectedTimes = Object.keys(currentTime)
      .map(id => +id)
      .filter((id) => id !== productId)
      .map((id) => currentTime?.[id]?.start);

    const times = availTimes.slotIds?.[productId]?.productInventoryModels.filter(t => {
      const timeSlot = t.slotStructureId.toString();
      const slot = slotId.id.toString();
      const maxTix = t.quantity;
      const numTix = convertCart(isExchange ? exchangeCart : cart, currentProduct.id).qty;
      const end = new Date(t.end);
      t.afterNow = end > now && maxTix >= numTix && !selectedTimes.includes(t.start) ? 'active' : 'inactive';
      return timeSlot === slot;
    });

    const firstAvailableTimeSlot = availTimes.slotIds?.[productId].productInventoryModels.find(
      item => item.afterNow === 'active'
    );

    const { price: columnPrice } = calcTixPrices(
      cart,
      currentProduct,
      slotId.id,
      slotStructure
    );
    const slotIdValue = +slotId.id;
    const needToChangeTimeSlot = !currentTime?.[productId];
    if (needToChangeTimeSlot) {
      this.props.selectTime(productId, firstAvailableTimeSlot);
    }

    const selectedTime = currentTime
      ? currentTime?.[+Object.keys(currentTime)?.[0]]?.slotStructureId
      : null;

    if (
      totalTicketsPrice !== columnPrice &&
      selectedTime === slotIdValue &&
      !currentProduct.isMultiTimed
    ) {
      this.setTotalTicketsPrice(columnPrice);
    }
    
    if (currentProduct.isMultiTimed && currentTime && cart) {
      const price = getMultiTimedProductTotalPrice(cart, availTimes, currentProduct.retailPrice, currentProduct.promotions);
      this.setTotalTicketsPrice(price);
    }

    let firstActiveIndex = -1;
    times.forEach((t, index) => {
      if (t.afterNow === 'active' && firstActiveIndex < 0) {
        firstActiveIndex = index;
      }
    });
    this.scrollTo(firstActiveIndex);

    return times;
  };

  changeSelectedTime = id => {
    const { times } = this.state;
    const { selectTime, productId } = this.props;
    const newSelected = times.find(t => t.id === id);
    if (newSelected.afterNow === 'inactive') {
      return;
    }
    selectTime(productId, newSelected);
  };

  scrollMore = (up, dist) => {
    const { slotId } = this.props;
    const amount = up ? -dist : dist;
    scroll.scrollMore(amount, { containerId: slotId.id });
  };

  scrollTo = index => {
    const { slotId } = this.props;
    scroll.scrollTo(60 * index, {
      duration: 800,
      delay: 10,
      smooth: 'easeInOutQuart',
      containerId: `${slotId.id}`
    });
  };

  setTotalTicketsPrice = price => {
    const { totalTicketsPrice, addTotalTicketsPrice } = this.props;
    if (totalTicketsPrice !== price) {
      addTotalTicketsPrice(+price);
    }
  };

  render() {
    const { slotId, currentTime, isExchange, client, productId, currentProduct } = this.props;
    const { times, price } = this.state;
    const x = Math.floor(price);
    const resultPrice = times?.[0]?.premiumPrice > 0 ? times[0].premiumPrice + x : price;

    return (
      <TimeSlotBlock onClick={() => this.setTotalTicketsPrice(resultPrice)}>
        <DayPartBox
          slotId={slotId}
          price={resultPrice}
          isExchange={isExchange || currentProduct.isMultiTimed}
          client={client}
        />
        <TimeSlotWrapper id={`${slotId.id}`}>
          {times.map(t => (
            <Element
              name={currentTime?.[productId]?.id === t.id ? 'selected' : t.afterNow}
              key={t.id}
            >
              <SingleTimeSlot
                active={currentTime?.[productId]?.id === t.id ? 'selected' : t.afterNow}
                timeSlot={t}
                changeSelectedTime={this.changeSelectedTime}
                name={currentTime?.[productId]?.id === t.id ? 'selected' : t.afterNow}
              />
            </Element>
          ))}
        </TimeSlotWrapper>
        <ScrollButtons
          clickDown={() => this.scrollMore(false, 240)}
          clickUp={() => this.scrollMore(true, 240)}
          client={client}
        />
      </TimeSlotBlock>
    );
  }
}

const mapStateToProps = state => {
  return {
    availTimes: state.availTimes,
    slotStructure: state.availTimes.slotStructPriceAdjustmentModels,
    cart: state.cart,
    exchangeCart: state.exchangeCart,
    currentProduct: state.currentProduct,
    currentTime: state.currentTime,
    isExchange: state.isExchange,
    client: state.client,
    totalTicketsPrice: state.totalTicketsPrice,
  };
};

const mapDispatchToProps = { selectTime, addTotalTicketsPrice };

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlots);
