import styled from "styled-components";

export const PrintingContent = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  background-color: ${(props) =>
    props.client === "cgg" ? "" : "rgba(255, 255, 255, 0.4)"};
`;
