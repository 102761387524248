export const MemberVenueIdEnum = {
  sn: 41,
  pike: 41,
  cgg: 44,
};

export const SalesTerminalTypeIdEnum = {
  sn: 33,
  pike: 361,
  cgg: 38,
};
