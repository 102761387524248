import React from 'react';
import { DayPartWrapper } from '../../Styles/ComponentStyles/TimeSlotSelection/DayPartBoxStyles';

const DayPartBox = props => {
  const { price, slotId, isExchange, client } = props;

  return (
    <>
      <DayPartWrapper client={client}>
        <h5>{slotId.name}</h5>
        {isExchange ? null : <h6>${price}</h6>}
      </DayPartWrapper>
    </>
  );
};

export default DayPartBox;
