import styled from "styled-components";
import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const BarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 7%;
  margin-top: 25px;

  .buttons-left {
    display: flex;

    & > :first-child {
      margin-right: 15px;
    }
  }
`;

export const CalendarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid white;
  padding-right: 35px;
  margin-right: 35px;
`;

export const CalendarIconWrapper = styled.div`
  margin-left: 12px;

  &:hover {
    cursor: pointer;
  }
`;

export const DatePickerIcon = styled.img`
  height: 45px;
  width: 45px;
  margin-left: 5px;
`;

export const RightBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;

  text-align: right;
  font-family: ${(props) => getStyle(props.client).font};
  line-height: 15px;
  font-size: 0.8em;
  font-weight: ${(props) => (props.client !== "cgg" ? "lighter" : "bold")};
`;

export const SubTitle = styled(RightBox)`
  margin-bottom: 10px;
  justify-content: center;
  font-size: 1.3em;
`;

export const popoverStyle = {
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
};
