export const calcTixPrices = (cart, currentProduct, slotId, slotStructure) => {
  // Creates a list of day part adjustments for promos
  const thisSlotStruc = slotStructure.filter(s => s?.slotStructureId?.toString() === slotId?.toString());

  // Calculates the total price for all tickets based on all possible adjustments
  let price = 0;
  let slotAdj = 0;
  const genAdm = currentProduct.retailPrice;
  let tixSummary = {
    0: { numTix: 0, regPrice: genAdm, display: currentProduct.displayLabel }
  };
  cart.products.forEach(ticket => {
    let ticketPrice = genAdm;
    if (currentProduct.isMultiTimed) {
      const selectedPromos = ticket.appliedPromotions.length ? ticket.appliedPromotions : [{promotionId: 0}];
      const tickets = selectedPromos.reduce((tix, promo) => {
        const productPromotionObj = currentProduct.promotions.find(avail => +promo.promotionId === +avail.promoId);
        const selectedInvIds = ticket.time ? Object.keys(ticket.time).map(id => +id) : [];
        const selectedIdsAndSlotStructures = selectedInvIds.map(id => [id, ticket.time?.[id]?.slotStructureId]);
        const adjustmentBySlots = selectedIdsAndSlotStructures.reduce((totalAdj, [productId, slotStructureId]) => {
          const slotStrAdjustmentsArray = slotStructure.filter(slot => (
            slot.childProduct === productId &&
            slot.slotStructureId === slotStructureId &&
            promo.promotionId === slot.promotionId
          ));
          const adjByTime = slotStrAdjustmentsArray.reduce((adjByTime, slotAdjObj) => adjByTime + slotAdjObj.adjustment, 0);

          return adjByTime + totalAdj;
        }, 0);
        const tixPromo = tixSummary?.[promo.promotionId];
        return {
          ...tixSummary,
          [promo.promotionId]: {
            ...tixPromo,
            numTix: tixPromo ? tixPromo.numTix + 1 : 1,
            regPrice: genAdm + (productPromotionObj?.adjustment || 0),
            display: productPromotionObj?.name || currentProduct.displayLabel,
            slotDisc: genAdm + (productPromotionObj?.adjustment || 0) + slotAdj + adjustmentBySlots,
          }
        };
      }, {});
      tixSummary = tickets;
    } else if (ticket.appliedPromotions.length) {
      ticket.appliedPromotions.forEach(promo => {
        const applPromo = currentProduct.promotions.find(avail => {
          return promo.promotionId.toString() === avail.promoId.toString();
        });
        const arrayOfSlotAdjustments = thisSlotStruc.filter(slot => promo.promotionId.toString() === slot.promotionId.toString());

        slotAdj = arrayOfSlotAdjustments.reduce((acc, cur) => cur.adjustment + acc, 0);
        
        ticketPrice += applPromo.adjustment;
        if (tixSummary[applPromo.promoId]) {
          tixSummary[applPromo.promoId].numTix++;
        } else {
          tixSummary[applPromo.promoId] = {
            numTix: 1,
            regPrice: genAdm + applPromo.adjustment,
            display: applPromo.name,
            slotDisc: genAdm + applPromo.adjustment + slotAdj
          };
        }
      });
    } else {
      const arrayOfSlotAdjustments = thisSlotStruc.filter(slot => slot.promotionId === 0);

      slotAdj = arrayOfSlotAdjustments.reduce((acc, cur) => cur.adjustment + acc, 0);
      
      tixSummary[0].numTix++;
      tixSummary[0].slotDisc = genAdm + slotAdj;
    }
    ticketPrice += slotAdj;
    price += ticketPrice;
  });

  return { tixSummary: tixSummary, price: price.toFixed(2) };
};
