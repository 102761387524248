import styled from 'styled-components';

export const TimeSlotBlock = styled.div`
  width: 240px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
`;

export const TimeSlotWrapper = styled.div`
  margin: 0;
  overflow: hidden;
  height: 475px;
  -webkit-overflow-scrolling: touch;
`;
