import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import { Router, Route } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { reducer } from "./Reducer/reducer";
import "bootstrap/dist/css/bootstrap.min.css";
import history from "./Actions/history";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

const isDevelopment = process.env.NODE_ENV === "development";
const buildSentryEnv = window.location.origin.includes("uat") ? "UAT" : "PROD";
const sentryDsn =
  "https://ef7154a2ec4fd34f270e20ccb45a7cde@o4507657321381888.ingest.us.sentry.io/4507816067072000";

Sentry.init({
  dsn: sentryDsn,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      unmask: ["*"],
      unblock: ["*"],
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api\.uat\.siaticketing\.com/,
    /^https:\/\/api\.siaticketing\.com/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  autoSessionTracking: true,
  environment: isDevelopment ? "development" : buildSentryEnv,
});

const replay = Sentry.getReplay();

if (replay) {
  replay.startBuffering();
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route render={(props) => <App {...props} />} />
    </Router>
  </Provider>,
  document.getElementById("root")
);
