import React from "react";
import styled from "styled-components";

import { Modal } from "reactstrap";
import { connect } from "react-redux";
import Button from "./Button";

import { TextWrapper } from "../Styles/ComponentStyles/IdelTimeOutStyles";

let counter;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  & > :first-child {
    margin-right: 15px;
  }
`;

class IdleTimeOutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 15,
    };
  }

  componentDidMount = () => {
    counter = setInterval(this.countdown, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(counter);
  };

  countdown = () => {
    if (this.state.count === 1) {
      this.props.handleStartOver();
    }
    const newCount = this.state.count - 1;
    this.setState({ count: newCount });
  };

  closeModal = () => {
    clearInterval(counter);
    this.props.handleClose();
  };

  render() {
    const { showModal, handleStartOver, client } = this.props;

    return (
      <Modal isOpen={showModal} centered={true}>
        <div style={{ margin: "5%" }}>
          <TextWrapper client={client}>
            <h4>Would you like more time?</h4>
            <p>Time before session timeout: {this.state.count}s </p>
          </TextWrapper>
          <ButtonWrapper client={client}>
            <Button label="NO" click={handleStartOver} client={client} />
            <Button
              label="YES"
              second={true}
              click={this.closeModal}
              client={client}
            />
          </ButtonWrapper>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client,
  };
};

export default connect(mapStateToProps)(IdleTimeOutModal);
