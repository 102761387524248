export default {
  style: {
    primary: "#005780",
    primaryHover: "#004666",
    primaryDisabled: "#A1C7E2",
    primaryLight: "#1C6B99",
    secondary: "#AF8F4B",
    secondaryHover: "#a3884e",
    disabled: "#DAD9D7",
    fontColor: "#ffff",
    font: "gotham",
    boldFont: "gothamBold",
    altFont: "cocon",
    altFont2: "knockout",
  },
  resources: {
    name: "Space Needle",
    memberVenueId: 41,
    city: "Seattle",
    voucherId: 226,
    salesTerminalTypeId: 361,
  },
};
