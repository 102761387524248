import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const PrinterWrapper = styled.div`
  height: 40vh;
  width: 520px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;

  h4 {
    font-size: 30px;
    color: ${(props) => getStyle(props.client).secondary};
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 100%;

  & div {
    max-width: 100%;
  }
`;
