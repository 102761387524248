import { calcTixPrices } from './ticketPriceHelper';

export const getTixInfoForCard = (cart, currentProduct, currentTime, slotStructure) => {
  const invIds = Object.keys(currentTime).map(id => +id);
  const currentTimeSlot = currentProduct.isMultiTimed ? null : currentTime?.[invIds[0]]?.slotStructureId; 
  const tixSummary = calcTixPrices(
    cart,
    currentProduct,
    currentTimeSlot,
    slotStructure
  ).tixSummary;
  let longDesc = '';

  for (let key of Object.keys(tixSummary)) {
    const { numTix, slotDisc, display } = tixSummary[key];

    if (numTix !== 0) {
      const singlePrice = slotDisc?.toFixed(2);
      const onePrice = numTix === 1 ? '' : `\n($${singlePrice} ea.)`;
      const total = (slotDisc * numTix).toFixed(2);
      const thisLine = `
        <tr style="white-space: pre;">
          <td>${numTix + ' '}</td>
          <td>${display}<span>${onePrice}</span></td>
          <td>$${total}</td>
        </tr>`;

      longDesc = longDesc + thisLine;
    }
  }
  return { __html: longDesc };
};
