import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const ClockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: ${(props) => (props.size ? props.size : "1.1em")};
  font-weight: ${(props) => (props.client !== "cgg" ? "lighter" : "bold")};
`;

export const SelectedDateWrapper = styled.div`
  p {
    font-size: ${(props) => (props.size ? props.size : "0.9em")};
    font-family: ${(props) => getStyle(props.client).boldFont};
  }
`;
