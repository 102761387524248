import React from "react";
import styled, { keyframes } from "styled-components";

const colorfade = keyframes`
    0% {background-color:RGBA(72,92,102,0.51)}
    50%  {background-color: #3D474A}
    100% {background-color: RGBA(72,92,102,0.51)}
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 80px;
`;

const LoadingBox = styled.div`
  background: #3d474a;
  height: 100%;
  width: ${(props) => (props.isFull ? "auto" : "15%")};
  color: white;
  margin: 0 auto;
  padding: ${(props) => (props.isFull ? "1px 6px" : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${colorfade} 2s ease-in infinite;
`;

const Loading = ({ isFull }) => (
  <LoadingWrapper>
    <LoadingBox isFull={isFull}>LOADING....</LoadingBox>
  </LoadingWrapper>
);

export default Loading;
