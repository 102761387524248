import React from "react";
import { connect } from "react-redux";

import { selectProduct } from "../Actions/actionCreator";

import {
  CardWrapper,
  ProdImage,
  CartTicketsTable,
  StepLabel,
} from "../Styles/ComponentStyles/ProdCardStyles";

const ProdCard = (props) => {
  const {
    key,
    prodName,
    subTitle,
    focus,
    cart,
    alternateText,
    img,
    isExchange,
    page,
    client,
    step,
  } = props;

  return (
    <CardWrapper
      focus={focus}
      key={key}
      cart={cart}
      page={page}
      client={client}
    >
      {step && <StepLabel>STEP {step}</StepLabel>}
      <ProdImage img={img} page={page} />

      <div style={{ padding: "3.5%" }}>
        <h3>{prodName?.toUpperCase()}</h3>
        <h6>{subTitle}</h6>
        <div className="descText">
          {isExchange ? (
            <div></div>
          ) : cart ? (
            <div
              style={{
                padding: focus ? "" : "10px",
                overflow: "auto",
                height: "auto",
                maxHeight: "220px",
              }}
            >
              <CartTicketsTable
                client={client}
                dangerouslySetInnerHTML={alternateText}
              />
            </div>
          ) : (
            <div dangerouslySetInnerHTML={alternateText} />
          )}
        </div>
      </div>
    </CardWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    isExchange: state.isExchange,
    client: state.client,
  };
};

const mapDispatchToProps = {
  selectProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProdCard);
