import React from "react";

import { connect } from "react-redux";

import Button from "./Button";
import { ButtonWrapper, TotalCountBox } from "../Styles/ViewStyles/CartStyles";
import Loading from "../Components/Loading";

const CountBox = (props) => {
  const { isExchange, client, orderStatus, handleClick, isLoading } = props;
  const { totalPrice, totalTax } = orderStatus;

  if (isLoading) {
    return (
      <ButtonWrapper client={client}>
        <Loading isFull />
      </ButtonWrapper>
    );
  }

  return orderStatus !== "no order" && !isExchange ? (
    <>
      <TotalCountBox client={client}>
        <p className="top">
          SUBTOTAL:{"  "}${(totalPrice - totalTax).toFixed(2)}
        </p>
        <p>
          TAX: {"  "}${totalTax.toFixed(2)}
        </p>
        <p className="bottom">
          TOTAL:{"  "}${totalPrice.toFixed(2)}{" "}
        </p>
      </TotalCountBox>
      <ButtonWrapper client={client}>
        <Button
          click={handleClick}
          label={`CHECKOUT`}
          bold
          second={client !== "cgg"}
          width="100%"
          height={"12.5vh"}
          client={client}
        />
      </ButtonWrapper>
    </>
  ) : (
    <ButtonWrapper client={client}>
      <Button
        click={handleClick}
        label={`CHECKOUT`}
        bold
        width="100%"
        second={client !== "cgg"}
        height={"12.5vh"}
        client={client}
      />
    </ButtonWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    orderStatus: state.orderStatus,
    isExchange: state.isExchange,
    client: state.client,
    isLoading: state.isLoading,
  };
};

export default connect(mapStateToProps)(CountBox);
