import React from "react";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import SelectedDate from "./DateTimeDisplay";
import {
  HeaderWrapper,
  SelectionTitle,
} from "../Styles/ComponentStyles/SelectionHeaderStyles";

const SelectionHeader = ({ header, subHeader, currentTime, client }) => {
  const getSelectedTimeSlot = () => {
    var start = new Date(currentTime.start);
    var end = new Date(currentTime.end);
    var timeSlot = start.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    timeSlot =
      timeSlot +
      " - " +
      end.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    return timeSlot;
  };

  const location = useLocation();
  const mainList = ["/ProductSelection", "/CityPassExchange"];
  const main = mainList.includes(location.pathname);

  return (
    <HeaderWrapper client={client} main={main}>
      <SelectionTitle>{header}</SelectionTitle>
      {subHeader ? <p>{subHeader}</p> : null}
      <div className="visitDate">
        <SelectedDate size={client !== "cgg" ? "1.4em" : "1em"} />
        {currentTime?.start ? (
          <span>{"@ " + getSelectedTimeSlot()}</span>
        ) : (
          <></>
        )}
      </div>
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    client: state.client,
  };
};

export default connect(mapStateToProps)(SelectionHeader);
