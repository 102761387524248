import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const PlusMinusBox = styled.button`
  width: 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffff;
  background-color: ${(props) => getStyle(props.client).primary};
  border: none;

  &:focus {
    outline: none;
  }
`;

export const CountBox = styled.div`
  width: ${(props) => (props.cart ? "90px" : "70px")}
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: ${(props) => getStyle(props.client).primaryLight};
  
  h2{
    margin: 0;
  }
`;

export const HcardWrapper = styled.div`
  background-color: white;
  min-height: 80px;
  margin: ${(props) => (props.cart ? "15px 0" : "10px 0 0 0")};
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-top: 0;
  }
`;

export const InformationBox = styled.div`
  color: ${(props) => (props.client !== "cgg" ? "#2b626d" : "black")}
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  font-family: ${(props) => getStyle(props.client).boldFont};
`;

export const QtyChangeToolsWrapper = styled.div`
  display: flex;
`;

export const EditBoxWrapper = styled.div`
  width: ${(props) => (props.cart ? "70px" : "96px")};
  display: flex;
  font-size: 1.1em;
  color: #fff;
  background: ${(props) => getStyle(props.client).primaryLight};
  justify-content: center;
  align-items: center;
`;
