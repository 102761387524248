import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const ThxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => (props.client !== "cgg" ? "40%" : "50%")};
  margin: 20% auto;
  padding: 2% 0;
  background-color: ${(props) =>
    props.client !== "cgg" ? "rgba(242, 242, 242, 0.7)" : ""};
`;

export const ThxHeaderWrapper = styled.div`
  margin: 0 auto 3% auto;

  h4 {
    color: ${(props) =>
      getStyle(props.client)[
        props.client !== "cgg" ? "secondary" : "fontColor"
      ]};
    font-family: ${(props) => getStyle(props.client).altFont2};
    font-size: 3em;
    letter-spacing: 0.06em;
  }
`;

export const ThxBodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 130px;
  }

  h4 {
    font-size: 1.5em;
    padding-left: 20px;
  }
`;

export const ThxButtonWrapper = styled.div`
  margin: 50px 0 0 60px;
`;

export const DigitalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const DigitalContent = styled.div`
  max-width: 70%;
  padding: 50px;
  font-size: 2em;
  font-weight: bold;
  color: ${(props) => (props.client === "cgg" ? "#fff" : "")};
  background-color: ${(props) =>
    props.client !== "cgg" ? "rgba(242, 242, 242, 0.7)" : ""};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const QrCodeStyles = {
  width: "300px",
  height: "300px",
  border: "5px solid #fff",
};
