import snImageBg from "../../Images/sn/background.png";
import cggImageBg from "../../Images/cgg/background.png";

import sn from "../../Styles/sn/styles";
import cgg from "../../Styles/cgg/styles";
import pike from "../../Styles/pike/styles";

const clientStyles = {
  sn,
  pike,
  cgg,
};

export const bgImages = {
  sn: snImageBg,
  pike: snImageBg,
  cgg: cggImageBg,
};

export const getStyle = (name) => {
  return clientStyles[name].style;
};

export const getResources = (name) => {
  return clientStyles[name].resources;
};
