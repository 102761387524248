import styled from "styled-components";

export const ProductsWrapper = styled.div`
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3% auto;
  margin-top: 0%;

  h6 {
    margin: auto;
  }

  p {
    text-align: center;
    font-size: 0.8em;
    margin: 0 4px;
  }

  .bottomWrapper {
    display: flex;
    justify-content: space-between;
    width: 24%;
    margin-top: 2%;
  }
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;
