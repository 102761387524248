import styled from "styled-components";

export const TimeSelectionWrapper = styled.div`
  margin: 0 7%;
`;

export const TimeSelectionBlock = styled.div`
  display: flex;
  justify-content: space-between;

  .outerTimeSlotDiv {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .innerTimeSlotDiv {
    display: flex;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0%;
`;
