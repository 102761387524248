import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const ArrowWrapper = styled.div`
  margin: 10px;

  .fa-chevron-right {
    &:hover {
      opacity: 0.7;
    }
  }
`;

const NextArrow = ({ onClick, client }) => {
  return (
    <ArrowWrapper onClick={onClick}>
      {client === 'cgg' ? (
        <FontAwesomeIcon
          icon={faChevronRight}
          style={{ fontSize: '2.5em' }}
          color="white"
        />
      ) : (
        <FontAwesomeIcon icon={faCaretRight} size="3x" color="white" />
      )}
    </ArrowWrapper>
  );
};

export default NextArrow;
