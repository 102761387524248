const weekday = ['SUN', 'MON', 'TUES', 'WED', 'THU', 'FRI', 'SAT'];
const month = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEPT',
  'OCT',
  'NOV',
  'DEC'
];
let dateRange = [];

const ReturnWeekday = date => {
  return `${weekday[date.getDay()]}`;
};

const ReturnMonth = date => {
  return `${month[date.getMonth()]}`;
};

const ReturnDay = date => {
  return `${date.getDate()}`;
};

const ReturnFullYear = date => {
  return `${date.getFullYear()}`;
};

//Returns an array of dates for a two week span from today
const TwoWeekDateRange = () => {
  dateRange = [];
  const D = new Date();
  for (let i = 0; i <= 14; i++) {
    let newDate = addDays(D, i);
    dateRange.push(newDate);
  }
  return dateRange;
};

// Takes in a date in any string representation of a date and returns it in 'mo/dy/year' format
const datePrettier = date => {
  const uglyDate = new Date(date);
  const prettyDate = `${uglyDate.getMonth() +
    1}/${uglyDate.getDate()}/${uglyDate.getFullYear()}`;
  return prettyDate;
};
const apiQueryDate = (date, isAddingToCart = false) => {
  const anotherUglyDate = new Date(date);
  const month = ('0' + (anotherUglyDate.getMonth() + 1)).slice(-2);
  const day = ('0' + anotherUglyDate.getDate()).slice(-2);
  const anotherPrettyDate = !isAddingToCart
    ? `${anotherUglyDate.getFullYear()}%2F${month}%2F${day}`
    : `${anotherUglyDate.getFullYear()}/${month}/${day}`;
  return anotherPrettyDate;
};
// Takes in a date and returns a date that is a multiple of days away
// Parameters: 1- date (can be any string representation of a day), 2- # of days to add (or subtract if negative)
const addDays = (date, mult = 1) => {
  const millisecsInADay = 86400000;
  let newDate = new Date(date.getTime() + mult * millisecsInADay);
  return newDate;
};

//takes in a dateTime string and
//formats a 24 hour dateTime into 12 hour time with AM/PM appended
const formatTime = date => {
  const d = new Date(date);
  let hh = d.getHours();
  let m = d.getMinutes();
  let dd = 'AM';
  let h = hh;
  if (h >= 12) {
    h = hh - 12;
    dd = 'PM';
  }
  if (h === 0) {
    h = 12;
  }

  //adds 0 before times with less than 2 digits
  m = m < 10 ? '0' + m : m;

  let replacement = h + ':' + m;
  replacement += ' ' + dd;

  return replacement;
};

const formatDateAndTimeOfVisit = (date, onlyDayNeeded = false) => {
  const startDate = new Date(date);
  const currentDateFormatted = ReturnMonth(startDate) + ' ' + ReturnDay(startDate);
  const currentTimeFormatted = formatTime(date);
  return onlyDayNeeded
    ? currentDateFormatted
    : `${currentDateFormatted} at ${currentTimeFormatted}`;
};

const getFirstSelectedDate = (currentTime) => {
  const invId = Object.keys(currentTime)[0];
  return currentTime?.[+invId]?.start;
};

module.exports = {
  weekday,
  month,
  ReturnWeekday,
  ReturnMonth,
  ReturnDay,
  ReturnFullYear,
  TwoWeekDateRange,
  dateRange,
  datePrettier,
  formatTime,
  addDays,
  apiQueryDate,
  formatDateAndTimeOfVisit,
  getFirstSelectedDate,
};
