// Takes in an object with all numerical values and adds them all together
const countPromoQty = promo => {
  let promoCount = 0;
  for (const key of Object.keys(promo)) {
    promoCount += promo[key];
  }
  return promoCount;
};

// Takes in a ticket-by-ticket cart and product id and returns a more simplified cart
const convertCart = (cart, id) => {
  const simplifiedCart = {
    id: id,
    qty: 0,
    promos: {}
  };
  cart.products.forEach(item => {
    if (item.productId === id) {
      simplifiedCart.qty += 1;

      if (item.appliedPromotions.length) {
        const add = simplifiedCart.promos[item.appliedPromotions[0].promotionId];
        if (add >= 0) {
          simplifiedCart.promos[item.appliedPromotions[0].promotionId]++;
        } else {
          simplifiedCart.promos[item.appliedPromotions[0].promotionId] = 1;
        }
      }
    }
  });
  return simplifiedCart;
};

const convertTixToCartCounts = (cart, productList, isExchange = false) => {
  const tix = {};
  const findPromoInfo = (id, product) => product.promotions.find(p => p.promoId === id);

  cart.products.forEach(t => {
    const useSecond = isExchange && t.appliedPromotions.length > 1;
    const { productId } = t;
    const disc = useSecond
      ? t.appliedPromotions[1].promotionId
      : t.appliedPromotions[0] && !isExchange
      ? t.appliedPromotions[0].promotionId
      : false;
    const prodInfo = productList.find(p => p.id === productId);

    const promoInfo = disc ? findPromoInfo(disc, prodInfo) : null;
    const promoKey = productId.toString(10) + disc.toString(10);

    if (!disc && !tix[productId]) {
      tix[productId] = {
        count: 0,
        name: prodInfo.displayLabel,
        desc: prodInfo.name,
        price: prodInfo.retailPrice
      };
    }

    if (disc && !tix[promoKey]) {
      tix[promoKey] = {
        count: 0,
        name: promoInfo.name,
        desc: prodInfo.name,
        price: promoInfo.adjustedRetailPrice
      };
    }

    if (disc) {
      tix[promoKey].count++;
    } else {
      tix[productId].count++;
    }
  });

  const tixArr = [];
  for (let key of Object.keys(tix)) {
    tixArr.push(tix[key]);
  }
  return tixArr;
};

const getMultiTimedProductDto = (cart) => {
  const newProducts = cart.products.reduce((array, product) => {
    const invIds = product.time ? Object.keys(product.time) : [];
    const prodList = invIds.map((id) => ({
      appliedPromotions: product.appliedPromotions,
      productId: product.productId,
      date: product.time?.[+id]?.start,
      productInvId: product.time?.[+id]?.id,
    }));

    return [
      ...array,
      ...prodList,
    ];
  }, []);

  return {
    ...cart,
    products: newProducts,
  };
};

const getPromotionDiscount = (productInventory, selectedPromotions, promotions, prodInfo) =>
  selectedPromotions.reduce((adjustmentByPromos, promoData) => {
    const promoGeneralAdjustment = promotions?.find(productGeneralPromo => productGeneralPromo.promoId === promoData.promotionId);
    const promoGenAdjPrice = promoGeneralAdjustment?.adjustment || 0;

    const selectedInvIds = prodInfo.time ? Object.keys(prodInfo.time).map(id => +id) : [];
    const discountByllFilters = selectedInvIds.reduce((discount, invId) => {
      const slotStrId = prodInfo.time[invId].slotStructureId;
      const adjModels = productInventory.slotStructPriceAdjustmentModels.filter((model) => (
        slotStrId === model.slotStructureId &&
        invId === model.childProduct &&
        promoData.promotionId === model.promotionId
      ));
      const discountForFilteredModels = adjModels.reduce((discountForModels, model) => 
        discountForModels + model.adjustment
      , 0);

      return discountForFilteredModels + discount;
    }, 0);

    return discountByllFilters + adjustmentByPromos + promoGenAdjPrice;
  }, 0);

const getMultiTimedProductTotalPrice = (cart, productInventory, retailPrice, promotions) => {
  const totalPriceAdjustment = cart.products.reduce((totalAdj, prodInfo) => {
    const selectedPromotions = prodInfo.appliedPromotions.length ? prodInfo.appliedPromotions : [{promotionId: 0}];
    const promotionsDiscount = getPromotionDiscount(productInventory, selectedPromotions, promotions, prodInfo);
    return promotionsDiscount + totalAdj + retailPrice;
  }, 0);

  return totalPriceAdjustment;
};

export {
  countPromoQty,
  convertCart,
  convertTixToCartCounts,
  getMultiTimedProductDto,
  getMultiTimedProductTotalPrice,
};
