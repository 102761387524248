import React from 'react';
import { ReturnWeekday, ReturnDay } from '../../Utils/Helpers/dateHelpers';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck as check } from '@fortawesome/free-solid-svg-icons';

import {
  changeDate,
  fetchProductsList,
  createCityPassExchangeOrder,
  selectTime
} from '../../Actions/actionCreator';

import { DateBox } from '../../Styles/ComponentStyles/DateForSelectorStyle';

const DateForSelector = props => {
  const {
    key,
    date,
    currentTab,
    isExchange,
    exchangeCart,
    createCityPassExchangeOrder,
    selectTime
  } = props;
  const brake = date.getDay() === 6 ? true : false;
  const selected = props.selectedDate.getDate() === date.getDate() ? true : false;

  const handleDateClick = () => {
    props.changeDate(date);
    if (isExchange) {
      const { products } = exchangeCart;
      const barcodes = products.map(prod => prod.externalBarcode);
      createCityPassExchangeOrder(barcodes);
      selectTime(null, {});
      return;
    }
    props.fetchProductsList(date, currentTab);
    props.show();
  };

  return (
    <DateBox key={key} brake={brake} selected={selected} onClick={handleDateClick}>
      <div>{`${date.getMonth() + 1}/${ReturnDay(date)}`} </div>
      <div> {ReturnWeekday(date)} </div>
      <div className="icon">
        {' '}
        <FontAwesomeIcon icon={check} size="1x" />
      </div>
    </DateBox>
  );
};

const mapStateToProps = state => {
  return {
    selectedDate: state.selectedDate,
    currentTab: state.currentTab,
    boca: state.bocaStatusWebSocket,
    ticket: state.ticketPrintWebSocket,
    isExchange: state.isExchange,
    exchangeCart: state.exchangeCart
  };
};

const mapDispatchToProps = {
  changeDate,
  fetchProductsList,
  createCityPassExchangeOrder,
  selectTime
};

export default connect(mapStateToProps, mapDispatchToProps)(DateForSelector);
