import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCaretLeft } from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components';

const ArrowWrapper = styled.div`
  margin: 10px;

  .fa-chevron-left {
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const PrevArrow = ({ onClick, client }) => {
  return (
    <ArrowWrapper onClick={onClick} style={{ margin: '10px' }}>
      {client === 'cgg' ? (
        <FontAwesomeIcon
          icon={faChevronLeft}
          style={{ fontSize: '2.5em' }}
          color={'white'}
        />
      ) : (
        <FontAwesomeIcon icon={faCaretLeft} size="3x" color={'white'} />
      )}
    </ArrowWrapper>
  );
};
