import React from 'react';

import { connect } from 'react-redux';

import { formatTime } from '../../Utils/Helpers/dateHelpers';
import { SingleSlotWrapper } from '../../Styles/ComponentStyles/TimeSlotSelection/SingleTimeSlotStyles';

const SingleTimeSlot = props => {
  const { timeSlot, active, changeSelectedTime, client } = props;
  const start = formatTime(timeSlot.start);
  const end = formatTime(timeSlot.end);

  return (
    <SingleSlotWrapper
      active={active}
      onClick={() => changeSelectedTime(timeSlot.id)}
      client={client}
    >
      <p className={active}>
        {start} - {end}
      </p>
    </SingleSlotWrapper>
  );
};

const mapStateToProps = state => {
  return {
    client: state.client
  };
};

export default connect(mapStateToProps)(SingleTimeSlot);
