export const bocaStatusWebSocketOnMessage = e => {
  console.log('Boca Status WebSocket Connection on message: ' + e.data);
  switch (e.data) {
    case 'online':
      sessionStorage.setItem('isPrinterOnline', 'true');
      break;
    case 'offline':
      sessionStorage.setItem('isPrinterOnline', 'false');
      break;
    default:
      console.log('default');
      break;
  }
};

export const ticketPrintWebSocketOnMessage = e => {
  console.log('Ticket Print WebSocket Connection on message: ' + e.data);
};
