import React from "react";
import Clock from "react-live-clock";
import { connect } from "react-redux";
import {
  ReturnWeekday,
  ReturnMonth,
  ReturnDay,
  ReturnFullYear,
} from "../Utils/Helpers/dateHelpers";

import {
  ClockWrapper,
  SelectedDateWrapper,
} from "../Styles/ComponentStyles/DateTimeDisplayStyles";

const DateTimeDisplay = (props) => {
  const { current, selectedDate, size, client, currentDate } = props;

  if (current) {
    return (
      <ClockWrapper client={client}>
        {`${ReturnWeekday(currentDate)}, ${ReturnMonth(currentDate)} ${ReturnDay(currentDate)}, ${ReturnFullYear(
          currentDate
        )}`}
        <br />
        <span>
          CURRENT TIME: <Clock format="h:mm A" ticking={true} />
        </span>
      </ClockWrapper>
    );
  } else {
    return (
      <SelectedDateWrapper size={size} client={client}>
        <p>{`${ReturnWeekday(selectedDate)}, ${ReturnMonth(selectedDate)}
         ${ReturnDay(selectedDate)}, ${ReturnFullYear(selectedDate)}`}</p>
      </SelectedDateWrapper>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    selectedDate: state.selectedDate,
    client: state.client,
    currentDate: state.currentDate,
  };
};

export default connect(mapStateToProps)(DateTimeDisplay);
