import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const UpgradeButton = styled.button`
  min-width: 200px;
  background-color: ${(props) =>
    props.client === "cgg"
      ? (props) => getStyle(props.client).primaryOrange
      : (props) => getStyle(props.client).secondary};
  border: none;
  color: ${(props) => getStyle(props.client).fontColor};

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${(props) => getStyle(props.client).secondaryHover};
  }

  h6 {
    font-family: ${(props) => getStyle(props.client).boldFont};
    margin: 0;
  }
`;

export const PromotionCard = styled.div`
  display: flex;
  justify-content: space-between;
  height: 165px;
  background-color: white;
  margin-top: 50px;
  .info-text {
    margin: 20px;

    h6 {
      font-family: ${(props) => getStyle(props.client).boldFont};
    }
  }
`;

export const InfoCardWrapper = styled.div`
  display: flex;
`;

export const ProductImage = styled.div`
  min-width: 250px;
  height: 165px;
`;
