import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { convertTixToCartCounts } from "../Utils/Helpers/helpers";
import {
  createPendingOrder,
  resetCart,
  selectUpgrade,
  resetOrder,
  resetSelectedTime,
} from "../Actions/actionCreator";

import TopBar from "../Components/TopBar";
import SelectionHeader from "../Components/SelectionHeader";
import HorizontalProductCard from "../Components/HorizontalSelectionProductCard";
import ProductCard from "../Components/ProductCard";
import Loading from "../Components/Loading";
import CountBox from "../Components/CountBox";
import { GenericModal } from "../Components/Modal";

import {
  PromoWrapper,
  HorizontalPromoWrapper,
  LoadingCartWrapper,
} from "../Styles/ViewStyles/CartStyles";

import { getTixInfoForCard } from "../Utils/Helpers/cartHelper";

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tickets: [],
      productShown: {
        longDesc: "",
        imgUrl: "",
        name: "MULTI-PRODUCT SELECTION",
        shortDesc: "You have selected multiple products for purchase",
      },
    };

    this.handleClick = () => {
      const { isExchange, history, createPendingOrder, exchangeCart } =
        this.props;

      if (!isExchange) {
        history.push("/Checkout");
        return;
      }
      const barcodeProvider =
        exchangeCart.products?.[0]?.externalBarcode?.startsWith("30845878")
          ? "CityPassC3"
          : "CityPass";
      exchangeCart.products.forEach(
        (item) => (item.barcodeProvider = barcodeProvider)
      );
      createPendingOrder(exchangeCart);
    };

    // Determines the number of different products there are.
    this.setProductShown = () => {
      const { cart, productList } = this.props;
      const productValues = {};
      const productIds = [];
      cart.products.forEach((t) => {
        if (!productValues[t.productId]) {
          productValues[t.productId] = true;
        }
      });
      productIds.push(...Object.keys(productValues));
      if (productIds.length === 1) {
        const id = productIds[0];
        const newProd = productList.find(
          (p) => p.id.toString() === id.toString()
        );
        this.setState({
          productShown: {
            longDesc: newProd.longDesc,
            imgUrl: newProd.imageUrl,
            name: newProd.name,
            shortDesc: newProd.shortDesc,
          },
        });
      }
    };

    this.closeModal = () => {
      const {
        isExchange,
        resetOrder,
        resetSelectedTime,
        history,
        currentProduct,
      } = this.props;
      resetOrder();

      if (!isExchange) {
        resetSelectedTime();
        const whereToRedirect = currentProduct.timedYN
          ? "/EntryTime"
          : "/QtySelection";
        history.push(whereToRedirect);
      }
    };
  }

  componentDidMount() {
    window.onbeforeunload = function () {
      window.setTimeout(function () {
        window.location = "/";
      }, 0);
      window.onbeforeunload = null; // necessary to prevent infinite loop
    };

    const {
      cart,
      createPendingOrder,
      exchangeCart,
      isExchange,
      productList,
      selectUpgrade,
    } = this.props;

    selectUpgrade(true);

    if (!isExchange) {
      const tix = convertTixToCartCounts(cart, productList);
      this.setProductShown();
      createPendingOrder(cart);
      this.setState({ tickets: tix });
    } else {
      const tickets = convertTixToCartCounts(exchangeCart, productList, true);
      this.setState({ tickets });
    }
  }

  render() {
    const { tickets } = this.state;
    const {
      currentProduct,
      isExchange,
      currentTime,
      cart,
      slotStructure,
      isKioskAllowedToUse,
      orderStatus,
      client,
      exchangeType,
    } = this.props;

    const longDesc = getTixInfoForCard(
      cart,
      currentProduct,
      currentTime,
      slotStructure
    );
    const countBoxOrLoading =
      (orderStatus !== "no order" || isExchange) && !orderStatus?.error;

    if (!isKioskAllowedToUse) {
      return <Redirect exact push to={"/"} />;
    }

    return (
      <>
        <GenericModal
          label={`Error: ${orderStatus?.message}`}
          open={orderStatus?.error}
          click={this.closeModal}
          confirmButtonLabel={!orderStatus?.error ? "CONTINUE" : null}
          client={client}
        />
        <TopBar title={exchangeType} />
        <SelectionHeader
          header={"REVIEW YOUR ORDER"}
          currentTime={currentTime}
        />

        <PromoWrapper>
          <HorizontalPromoWrapper>
            {tickets.map((tix) => (
              <HorizontalProductCard
                key={tix.name + tix.desc}
                mainProd
                cart
                name={tix.name}
                currentProductId={currentProduct.id}
                totalCount={tix.count}
                promo={[]}
                {...this.props}
              />
            ))}
          </HorizontalPromoWrapper>
          <div>
            <ProductCard
              id={currentProduct.id}
              prodText={currentProduct.longDesc}
              img={currentProduct.imageUrl}
              prodName={currentProduct.name}
              subTitle={currentProduct.shortDesc}
              alternateText={longDesc}
              focus
              cart
              page={4}
            />
            {countBoxOrLoading ? (
              <CountBox handleClick={this.handleClick} />
            ) : (
              <LoadingCartWrapper>
                <Loading isFull />
              </LoadingCartWrapper>
            )}
          </div>
        </PromoWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    currentProduct: state.currentProduct,
    exchangeCart: state.exchangeCart,
    isExchange: state.isExchange,
    orderStatus: state.orderStatus,
    isKioskAllowedToUse: state.isKioskAllowedToUse,
    productList: state.fullProductList,
    currentTime: state.currentTime,
    slotStructure: state.availTimes.slotStructPriceAdjustmentModels,
    client: state.client,
    exchangeType: state.exchangeType,
  };
};

const mapDispatchToProps = {
  createPendingOrder,
  resetCart,
  selectUpgrade,
  resetOrder,
  resetSelectedTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
