import styled from "styled-components";
import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const CheckoutLoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;

  h3 {
    color: white;
    text-align: center;
    margin-bottom: 3%;
    font-family: ${(props) => getStyle(props.client).boldFont};
  }
`;
